import axios from '../utils/axiosSetup';

export const getAllSharedUsers = async () => {
    const response = await axios.get('/api/private/share');
    return response.data;
};

export const generateInviteLink = async () => {
    const response = await axios.get('/api/private/share/invite/link');
    return response.data.share_url;
};

export const sendInvitationEmail = async (email: string) => {
    try {
        const response = await axios.post('/api/private/share/invite/email', {email});
        return response.data;
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || '不明なエラーが発生しました。');
        } else {
            throw new Error('ネットワークエラーが発生しました。');
        }
    }
}

export const leaveShare = async () => {
    const response = await axios.get('/api/private/share/leave');
    return response.data;
};

export const removeShare = async (email: string) => {
    const response = await axios.post('/api/private/share/remove', {email});
    return response.data;
};

export const acceptInvitation = async (shareId: string) => {
    const response = await axios.post(`/api/private/share/invitation/${shareId}`);
    return response.data;
};