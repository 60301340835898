import React, { useState } from "react";
import "../styles/pages/NewTop.css";
import SideBarIcon from "../assets/images/icons/shopping-bag.png";
import TopIcon from "../assets/images/icons/top-image-shopping-woman.png";

const TopPage: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleNavClick = (sectionId: string) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
        setIsMenuOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="sp-top-page">
            {/* ナビゲーションバー */}
            <header className="sp-navbar">
                <div className="sp-container">
                    <div className="sp-logo">
                        <img src={SideBarIcon} alt="Smart Pantry Logo" className="sp-logo-img" />
                        <span>Smart Pantry</span>
                    </div>
                    <div className="sp-menu-icon" onClick={toggleMenu}>
                        &#9776;
                    </div>
                    <nav className={`sp-nav ${isMenuOpen ? "open" : ""}`}>
                        <ul className="sp-nav-links">
                            <li>
                                <a href="#features" onClick={() => handleNavClick("features")}>機能</a>
                            </li>
                            <li>
                                <a href="#how-it-works" onClick={() => handleNavClick("how-it-works")}>使い方</a>
                            </li>
                            <li>
                                <a href="#testimonials" onClick={() => handleNavClick("testimonials")}>お客様の声</a>
                            </li>
                            <li>
                                <a href="#pricing" onClick={() => handleNavClick("pricing")}>価格</a>
                            </li>
                            <li><a href="/login">ログイン</a></li>
                        </ul>
                    </nav>
                </div>
            </header>

            {/* ヒーローセクション */}
            <section className="sp-hero-section">
                <div className="sp-container sp-hero-container">
                    <div className="sp-hero-content">
                        <h1>その食材の賞味期限覚えていますか。</h1>
                        <p>Smart Pantryなら、食品の在庫の管理だけでなく賞味期限や買い物メモもお手のもの。</p>
                        <a href="/register" className="sp-cta-button">今すぐ始める</a>
                    </div>
                    <div className="sp-hero-image">
                        <img src={TopIcon} alt="Shopping Woman" className="sp-top-image" />
                    </div>
                </div>
            </section>

            {/* 機能セクション */}
            <section id="features" className="sp-features-section">
                <div className="sp-container">
                    <h2>主な機能</h2>
                    <div className="sp-features-grid">
                        <div className="sp-feature-item">
                            <h3>食品在庫管理</h3>
                            <p>食品の在庫を簡単に把握し、使い切るタイミングを見逃しません。</p>
                        </div>
                        <div className="sp-feature-item">
                            <h3>賞味期限通知</h3>
                            <p>賞味期限が近づくとお知らせ。食品ロスを減らし、節約につながります。</p>
                        </div>
                        <div className="sp-feature-item">
                            <h3>買い物リスト作成</h3>
                            <p>必要なものをリスト化し、買い物をスムーズに管理できます。</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* 使い方セクション */}
            <section id="how-it-works" className="sp-how-it-works-section">
                <div className="sp-container">
                    <h2>使い方</h2>
                    <div className="sp-how-it-works-steps">
                        <div className="sp-step-item">
                            <h3>1. アカウント作成</h3>
                            <p>簡単にアカウントを作成して、食品の在庫管理を始めましょう。</p>
                        </div>
                        <div className="sp-step-item">
                            <h3>2. 食品を追加</h3>
                            <p>アプリで食品をスキャンし、在庫に追加します。</p>
                        </div>
                        <div className="sp-step-item">
                            <h3>3. 賞味期限を管理</h3>
                            <p>賞味期限の管理と買い物メモで必要なものをリスト化します。</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* お客様の声セクション */}
            <section id="testimonials" className="sp-testimonials-section">
                <div className="sp-container">
                    <h2>お客様の声</h2>
                    <div className="sp-testimonials-grid">
                        <div className="sp-testimonial-item">
                            <p>“出先から家の食材の在庫を確認できるので、家でわざわざ確認する手間が減りました。”</p>
                            <h4>- Aさん</h4>
                        </div>
                        <div className="sp-testimonial-item">
                            <p>“家族全員で買い物リストを共有できるので、買い忘れがなくなりました。おすすめです！”</p>
                            <h4>- Bさん</h4>
                        </div>
                    </div>
                </div>
            </section>

            {/* 価格セクション */}
            <section id="pricing" className="sp-pricing-section">
                <div className="sp-container">
                    <h2>料金プラン</h2>
                    <div className="sp-pricing-grid">
                        <div className="sp-pricing-item">
                            <h3>無料プラン</h3>
                            <p>基本的な機能をすべて無料で利用できます。</p>
                        </div>
                        <div className="sp-pricing-item">
                            <h3>💎Proプラン</h3>
                            <p>無料プランでは制限されていた機能を含め全て使うことができます。</p>
                            <p>月額 300円</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* フッター */}
            <footer className="sp-footer">
                <div className="sp-container">
                    <p>© 2024 Smart Pantry. All rights reserved.</p>
                    <ul className="sp-footer-links">
                        <li><a href="/privacy">プライバシー</a></li>
                        <li><a href="/terms">利用規約</a></li>
                        <li><a href="mailto:contact@smartpantry.jp">サポート</a></li>
                    </ul>
                </div>
            </footer>
        </div>
    );
};

export default TopPage;
