import React, { useEffect, useState } from "react";
import { getAllNotifications, toggleReadState, getReadState } from "../../api/notificationApi";
import "../../styles/components/notifications/Notification.css";
import { formatDate } from "../../utils/dateFormer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

interface Notification {
    id: number; // ユニークなID
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
    isRead: boolean; // 既読状態を保持
}

// URLをリンクに変換する関数
const convertToLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) =>
        urlRegex.test(part) ? (
            <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'blue', textDecoration: 'underline' }}
            >
                {part}
            </a>
        ) : (
            part
        )
    );
};

const NotificationList = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
            const data = await getAllNotifications();
            const mappedData = await Promise.all(
                data.map(async (notification: any, index: number) => {
                    const notificationId = notification.ID ?? index;
                    const readState = await getReadState(notificationId);
                    return {
                        id: notificationId,
                        title: notification.title,
                        content: notification.content,
                        createdAt: notification.CreatedAt,
                        updatedAt: notification.UpdatedAt,
                        isRead: readState.is_read ?? false, // 未読をデフォルトに設定
                    };
                })
            );

            const sortedData = mappedData.sort(
                (a: Notification, b: Notification) =>
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
            setNotifications(sortedData);
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
        }
    };

    const handleToggleRead = async (notificationId: number) => {
        try {
            const updatedReadState = await toggleReadState(notificationId);
            setNotifications((prevNotifications) =>
                prevNotifications.map((notification) =>
                    notification.id === notificationId
                        ? { ...notification, isRead: updatedReadState.is_read }
                        : notification
                )
            );
        } catch (error) {
            console.error("Failed to toggle read state:", error);
        }
    };

    return (
        <div className="main-content">
            <div className="announcement-section">
                <h2>運営からのお知らせ</h2>
                {notifications.map((notification) => (
                    <div
                        className={`announcement ${notification.isRead ? "read" : "unread"}`}
                        key={notification.id} // 一意のキーを設定
                    >
                        <div className="announcement-text">
                            <h3>
                                <FontAwesomeIcon icon={faCircleExclamation} /> {notification.title}
                            </h3>
                            <p style={{ whiteSpace: "pre-line" }}>{convertToLinks(notification.content)}</p>
                            <br />
                            <div className="announcement-date">
                                {notification.createdAt === notification.updatedAt
                                    ? formatDate(notification.createdAt)
                                    : `${formatDate(notification.createdAt)} (更新: ${formatDate(notification.updatedAt)})`}
                            </div>
                            <button
                                className="read-toggle-button"
                                onClick={() => handleToggleRead(notification.id)}
                            >
                                {notification.isRead ? "未読にする" : "既読にする"}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NotificationList;
