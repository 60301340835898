import axios from '../utils/axiosSetup';

export const addNotification = async (title: string, content: string) => {
    const response = await axios.post('/api/private/admin/notifications', { title, content });
    return response.data;
};

export const updateNotification = async (notification: { id: number, title: string, content: string }) => {
    const response = await axios.put(`/api/private/admin/notifications/${notification.id}`, notification);
    return response.data;
};

export const getNotificationById = async (id: string) => {
    const response = await axios.get(`/api/private/admin/notifications/${id}`);
    return response.data;
};

export const getAllNotifications = async () => {
    const response = await axios.get('/api/private/notifications');
    return response.data;
};

export const deleteNotification = async (id: number) => {
    const response = await axios.delete(`/api/private/admin/notifications/${id}`);
    return response.data;
};

// 既読関連

export const toggleReadState = async (notificationId: number) => {
    const response = await axios.put(`/api/private/read_state/${notificationId}`);
    return response.data;
};

export const getReadState = async (notificationId: number) => {
    try {
    const response = await axios.get(`/api/private/read_state/${notificationId}`);
    return response.data;
    } catch (error) {
        return { is_read: false };
    }
}